import axios from 'axios'
import applyAppTokenRefreshInterceptor from './refreshToken.service'
import TokenService from './token.service'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
applyAppTokenRefreshInterceptor(api)

api.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getLocalAccessToken()

    if (config?.headers && accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// api.interceptors.response.use(
//   (res) => {
//     return res
//   },
//   async (error) => {
//     /**
//      * Refresh token
//      * @see https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
//      */

//     if (error.response.status === 401) {
//       document.dispatchEvent(new Event('UserLogout'))
//     }

//     return Promise.reject(error)
//   }
// )

export default api
