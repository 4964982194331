import styled, { css } from 'styled-components'

export const StyledLoaderWrapper = styled.div`
  margin: 100px 0;
`

export const StyledPatientCardWrapper = styled.div`
  text-align: center;
`

export const StyledDoctorCategorySquare = styled.div<{
  $categoryColor?: string | null
}>`
  display: inline-block;
  position: relative;
  top: 1px;
  width: 4px;
  height: 14px;
  margin-right: 1px;
  ${({ $categoryColor }) =>
    $categoryColor &&
    css`
      background-color: ${$categoryColor};
    `};
`
