export const downloadFileLink = (url: string) => {
  // create "a" HTML element with href to file & click
  const link = document.createElement('a')
  link.href = url
  // link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  return true
}
