import { Navigate, useLocation } from 'react-router'
import { useAuth } from '../hooks/useAuth'
import { UserType } from '../models/User'

export const RequireDLUser = ({
  users,
  children,
}: {
  users: UserType[]
  children: JSX.Element
}) => {
  let auth = useAuth()
  let location = useLocation()

  let userType: UserType | null = null

  switch (auth.state?.user?.role?.name) {
    case 'superadmin':
      userType = UserType.SuperAdmin
      break
    case 'admin':
      userType = UserType.Admin
      break
    case 'doctor':
      userType = UserType.Doctor
      break

    case 'secretary':
      userType = UserType.Secretary
      break

    case 'patient':
      userType = UserType.Patient
      break
    default:
      break
  }

  if (!userType || !users.includes(userType)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}
