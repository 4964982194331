import React, { FC, useMemo, useState } from 'react'
import { StyledActionButton, StyledLoadingIconWrapper } from './styled'

interface ActionButtonType
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: 'save' | 'edit' | 'delete' | 'download'
  isLoading?: boolean
  isAsync?: boolean
}

export const ActionButton: FC<ActionButtonType> = (props) => {
  const {
    className,
    title,
    onClick,
    buttonType,
    disabled,
    isLoading = false,
    isAsync = false,
  } = props

  const [loading, setLoading] = useState<boolean>(isLoading)

  const buttonIconClassName = useMemo(() => {
    switch (buttonType) {
      case 'delete':
        return `bi bi-trash3`
      case 'download':
        return `bi bi-cloud-arrow-down`
      case 'edit':
        return `bi bi-pencil`
      case 'save':
        return `far fa-save`
      default:
        return ''
    }
  }, [buttonType])

  return (
    <StyledActionButton
      className={`btn icon btn-sm ${
        buttonType === 'delete' ? 'btn-secondary' : ''
      } ${buttonType === 'edit' ? 'btn-primary' : ''} ${
        buttonType === 'save' ? 'btn-success' : ''
      } ${isLoading || loading ? 'is-loading' : ''} ${className}`}
      title={title}
      onClick={async (e) => {
        if (onClick) {
          // This is the only reliable way to check if a function is asynchronous
          // const onClickIsPromise = onClick.constructor.name === 'AsyncFunction'
          // We only set loading if the function is actually async
          // to avoid useless re-renders
          if (isAsync) setLoading(true)
          // We can await onclick even if it's not asynchronous
          // it won't change its behavior
          await onClick(e)
          if (isAsync) setLoading(false)
        }
      }}
      disabled={disabled || isLoading || loading}
    >
      <StyledLoadingIconWrapper>
        <i className="fas fa-circle-notch fa-spin"></i>
      </StyledLoadingIconWrapper>

      <i className={buttonIconClassName}></i>
    </StyledActionButton>
  )
}
