import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { PatientAppointmentChip } from '../../../atoms/AppointmentChip/AppointmentChip'
import { Avatar } from '../../../atoms/Avatar/Avatar'
import Card from '../../../atoms/Card/Card'
import { LoaderFull } from '../../../atoms/Loader/Loader'
import { Pagination } from '../../../atoms/Pagination/Pagination'
import Table from '../../../atoms/Table/Table'
import { TableSearchInput } from '../../../atoms/TableSearchInput/TableSearchInput'
import {
  PageContent,
  PageHeading,
  PageTitleContainer,
} from '../../../components/Layout'
import { useAuth } from '../../../hooks/useAuth'
import { IAppointment } from '../../../models/Appointment'
import { IPatient } from '../../../models/Patient'
import APIService from '../../../services/api'
import { StyledLoadMoreLink, StyledPatientsTableHead } from './styled'

const PAGE_LIMIT = 10

const DashboardPage = () => {
  const navigate = useNavigate()
  const { state } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const debouncedSearchTermTimeout = useRef<NodeJS.Timeout>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingNextAppointments, setIsLoadingNextAppointments] =
    useState<boolean>(false)
  const [patientsPage, setPatientsPage] = useState<number>(1)
  const [patientsTotalPages, setPatientsTotalPages] = useState<number>(1)
  const [patientsTotal, setPatientsTotal] = useState<number>()
  const [patients, setPatients] = useState<IPatient[]>([])
  const [nextAppointments, setNextAppointments] = useState<IAppointment[]>([])
  const [nextAppointmentsPage, setNextAppointmentsPage] = useState<number>(1)
  const [nextAppointmentsTotal, setNextAppointmentsTotal] = useState<number>(0)

  const getPatients = async () => {
    setIsLoading(true)
    try {
      const { data } = await APIService.patient.patients.list({
        search: debouncedSearchTerm,
        page: patientsPage,
        limit: PAGE_LIMIT,
      })
      const { data: paginatedResponse } = data
      setPatients(paginatedResponse.items)
      setPatientsTotal(paginatedResponse.total)
      setPatientsTotalPages(Math.ceil(paginatedResponse.total / PAGE_LIMIT))
    } catch (error) {}
    setIsLoading(false)
  }

  const getNextAppointments = async () => {
    setIsLoadingNextAppointments(true)
    try {
      const dateStart = new Date()
      dateStart.setUTCHours(0, 0, 0, 0)

      const { data } = await APIService.patient.patients.getAppointments({
        page: nextAppointmentsPage,
        limit: 7,
        date_start: dateStart.toISOString(),
        sortMethod: 'asc',
      })
      setNextAppointments((d) => [...d, ...data.data.items])
      setNextAppointmentsTotal(data.data.total)
    } catch (error) {}
    setIsLoadingNextAppointments(false)
  }

  useEffect(() => {
    debouncedSearchTermTimeout.current = setTimeout(() => {
      setPatientsPage(1)
      setDebouncedSearchTerm(searchTerm)
    }, 600)
    return () => clearTimeout(debouncedSearchTermTimeout.current)
  }, [searchTerm])

  useEffect(() => {
    getPatients()
  }, [patientsPage, debouncedSearchTerm])

  useEffect(() => {
    getNextAppointments()
  }, [nextAppointmentsPage])

  return (
    <>
      <Helmet title={`Dashboard - ${process.env.REACT_APP_NAME}`} />
      <PageHeading>
        <PageTitleContainer
          title={`Welcome ${state?.user?.name}`}
          subtitle={undefined}
        />
      </PageHeading>
      <PageContent className="mb-4">
        <section className="row">
          <div className="col-12 col-lg-8 order-2 order-lg-1">
            <div className="row">
              <div className="col-12">
                <Card
                  title={`Patients ${
                    patientsTotal && patientsTotal > 0
                      ? `(${patientsTotal})`
                      : ''
                  }`}
                >
                  <StyledPatientsTableHead>
                    <TableSearchInput
                      value={searchTerm}
                      placeHolder="Search patients by name, email or phone"
                      // buttonLabel="Search"
                      onValueChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </StyledPatientsTableHead>
                  <Table
                    className="mb-4"
                    tableSize="md-2"
                    isTableHovered={true}
                    isLoading={isLoading}
                    tableHead={[
                      { title: '' },
                      { title: 'Name' },
                      { title: 'Surname' },
                      { title: 'Date of birth' },
                      { title: 'Email' },
                      { title: 'Phone' },
                      { title: 'Action' },
                    ]}
                    rows={patients.map((patient, i) => {
                      return {
                        className: 'clickable',
                        onClick: () => {
                          navigate(`/dlnd/patient/${patient.uuid}`)
                        },
                        cols: [
                          {
                            className: 'text-center',
                            children: (
                              <Avatar
                                content={`${patient.name.charAt(
                                  0
                                )}${patient.surname.charAt(0)}`}
                                size="md"
                              />
                            ),
                          },
                          {
                            className: 'text-bold-500',
                            children: <>{patient.name}</>,
                          },
                          {
                            className: 'text-bold-500',
                            children: <>{patient.surname}</>,
                          },
                          {
                            children: <>{patient.date_of_birth}</>,
                          },
                          {
                            children: <>{patient.email}</>,
                          },
                          {
                            children: <>{patient.mobile}</>,
                          },
                          {
                            children: (
                              <>
                                <div className="p-3 py-4 mb-2 text-center rounded">
                                  <svg
                                    className="bi"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                  >
                                    <use xlinkHref="/assets/images/bootstrap-icons.svg#arrow-right"></use>
                                  </svg>
                                </div>
                              </>
                            ),
                          },
                        ],
                      }
                    })}
                  />

                  <Pagination
                    currentPage={patientsPage}
                    totalPages={patientsTotalPages}
                    onChangePage={(page) => {
                      setPatientsPage(page)
                    }}
                  />
                </Card>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 order-1 order-lg-2">
            <div className="row">
              <div className="col-12">
                <Card title="Next appointments">
                  {nextAppointments && nextAppointments.length > 0 && (
                    <>
                      {nextAppointments.map((appointment) => (
                        <PatientAppointmentChip
                          key={appointment.id}
                          appointment={appointment}
                          showPatient={true}
                          onClick={() => {
                            navigate(
                              `/dlnd/patient/${appointment.patient?.uuid}`
                            )
                          }}
                        />
                      ))}
                      {!isLoadingNextAppointments &&
                        nextAppointments.length < nextAppointmentsTotal && (
                          <StyledLoadMoreLink>
                            <span
                              onClick={() => {
                                setNextAppointmentsPage((d) => d + 1)
                              }}
                            >
                              Load more
                            </span>
                          </StyledLoadMoreLink>
                        )}
                    </>
                  )}
                  {isLoadingNextAppointments && <LoaderFull />}
                  {!isLoadingNextAppointments &&
                    nextAppointments &&
                    nextAppointments.length === 0 && (
                      <p className="text-center">
                        No upcoming appointments found
                      </p>
                    )}
                </Card>
              </div>
            </div>
          </div>
        </section>
      </PageContent>
    </>
  )
}

export default DashboardPage
