import { IUser } from '../models/User'

export interface IAuthToken {
  accessToken: string
  refreshToken: string
  expiresAt?: string | null
}

const getLocalRefreshToken = (): string | null => {
  const authTokenRaw = localStorage.getItem('authToken')
  const authToken: IAuthToken | null = authTokenRaw
    ? JSON.parse(authTokenRaw)
    : null
  return authToken?.refreshToken || null
}

const getLocalAccessToken = (): string | null => {
  const authTokenRaw = localStorage.getItem('authToken')
  const authToken: IAuthToken | null = authTokenRaw
    ? JSON.parse(authTokenRaw)
    : null
  return authToken?.accessToken || null
}

const updateLocalAccessToken = (token: string, expiresAt?: string | null) => {
  const authTokenRaw = localStorage.getItem('authToken')
  const authToken: IAuthToken = authTokenRaw ? JSON.parse(authTokenRaw) : {}
  authToken.accessToken = token
  authToken.expiresAt = expiresAt
  localStorage.setItem('authToken', JSON.stringify(authToken))
}

const setAuthToken = (authToken: IAuthToken) => {
  localStorage.setItem('authToken', JSON.stringify(authToken))
}

const removeAuthToken = () => {
  localStorage.removeItem('authToken')
}

const getUser = (): IUser | null => {
  const userRaw = localStorage.getItem('userDL')
  const user: IUser | null = userRaw ? JSON.parse(userRaw) : null
  return user
}

const setUser = (user: IUser) => {
  localStorage.setItem('userDL', JSON.stringify(user))
}

const removeUser = () => {
  localStorage.removeItem('userDL')
}

const getUsername = (): string | null => {
  const usernameRaw = localStorage.getItem('usernameDL')
  const username: string | null = usernameRaw ? JSON.parse(usernameRaw) : null
  return username
}

const setUsername = (username: string) => {
  localStorage.setItem('usernameDL', JSON.stringify(username))
}

const removeUsername = () => {
  localStorage.removeItem('usernameDL')
}

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  setAuthToken,
  removeAuthToken,
  getUser,
  setUser,
  removeUser,
  getUsername,
  setUsername,
  removeUsername,
}

export default TokenService
