import styled from 'styled-components'

export const StyledLoader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const StyledLoaderTable = styled.div``
