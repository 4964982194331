import { IAppointment, IAppointmentCalendar } from '../../../models/Appointment'
import api from '../../api.service'
import { IResponse } from '../types'
import { AppointmentPayloadType } from './types'

// Appointments Calendar
const calendar = ({
  start = null,
  end = null,
  clinic = null,
  doctor = null,
  category = null,
}: {
  start?: string | null
  end?: string | null
  clinic?: number | null
  doctor?: number | null
  category?: number | null
}) => {
  return api.get<IResponse<IAppointmentCalendar[]>>(
    `/api/manage/appointments/calendar`,
    {
      params: {
        start,
        end,
        clinic,
        doctor,
        category,
      },
    }
  )
}

const getAppointment = (appointmentId: number) => {
  return api.get<IAppointment>(`/api/manage/appointment/${appointmentId}`)
}

const createAppointment = (payload: AppointmentPayloadType) => {
  return api.post<IAppointment>(`/api/manage/appointments`, payload)
}

const editAppointment = (
  appointmentId: number,
  payload: AppointmentPayloadType
) => {
  return api.put<IAppointment>(
    `/api/manage/appointment/${appointmentId}`,
    payload
  )
}

const deleteAppointment = (appointmentId: number) => {
  return api.delete(`/api/manage/appointment/${appointmentId}`)
}

const API = {
  calendar,
  getAppointment,
  createAppointment,
  editAppointment,
  deleteAppointment,
}

export default API
