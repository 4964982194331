import { useState } from 'react'
import { StyledButton, StyledLoadingIconWrapper } from './styled'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  isAsync?: boolean
  children?: React.ReactNode
}

export const Button = (props: IProps) => {
  const {
    isLoading = false,
    isAsync = false,
    onClick,
    disabled,
    children,
    className,
  } = props
  const [loading, setLoading] = useState<boolean>(isLoading)
  return (
    <StyledButton
      {...props}
      className={`${isLoading || loading ? 'is-loading' : ''} ${className}`}
      disabled={isLoading || loading || disabled}
      onClick={async (e) => {
        if (onClick) {
          // This is the only reliable way to check if a function is asynchronous
          // const onClickIsPromise = onClick.constructor.name === 'AsyncFunction'
          // We only set loading if the function is actually async
          // to avoid useless re-renders
          if (isAsync) setLoading(true)
          // We can await onclick even if it's not asynchronous
          // it won't change its behavior
          await onClick(e)
          if (isAsync) setLoading(false)
        }
      }}
    >
      <StyledLoadingIconWrapper>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </StyledLoadingIconWrapper>

      {children}
    </StyledButton>
  )
}
