import React from 'react'
import { RouteType } from '../models/RouteType'

const DashboardPage = React.lazy(
  () => import('../views/admin/dashboard/DashboardPage')
)
const PatientsListPage = React.lazy(
  () => import('../views/admin/patients/list/PatientsListPage')
)
const DoctorsListPage = React.lazy(
  () => import('../views/admin/doctors/list/DoctorsListPage')
)
const ServicesPage = React.lazy(
  () => import('../views/admin/services/list/Services')
)
const ServicePage = React.lazy(
  () => import('../views/admin/services/edit/Service')
)
const TestPage = React.lazy(() => import('../views/admin/test/Test'))

const routes: RouteType[] = [
  { index: true, element: <DashboardPage /> },
  { path: 'patients/list', element: <PatientsListPage archived={false} /> },
  { path: 'patients/archive', element: <PatientsListPage archived={true} /> },
  { path: 'doctors/list', element: <DoctorsListPage deleted={false} /> },
  { path: 'doctors/trash', element: <DoctorsListPage deleted={true} /> },
  { path: 'services/list', element: <ServicesPage /> },
  { path: 'service/new', element: <ServicePage /> },
  { path: 'service/:serviceId', element: <ServicePage /> },
  { path: 'test', element: <TestPage /> },
]

export default routes
