import styled from 'styled-components'

export const StyledPatientsTableHead = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledLoadMoreLink = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 8px 0;

  span {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`
