import styled from 'styled-components'

export const StyledLoadingIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

export const StyledButton = styled.button`
  position: relative;

  ${StyledLoadingIconWrapper} {
    display: none;
  }

  &.is-loading {
    ${StyledLoadingIconWrapper} {
      display: flex;
    }
  }
`
