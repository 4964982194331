// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
//
// HOW TO USE:
// import applyAppTokenRefreshInterceptor from 'axios.refresh_token.2.js';
// import axios from 'axios';
// ...
// applyAppTokenRefreshInterceptor(axios); // register the interceptor with all axios instance
// ...
// - Alternatively:
// const apiClient = axios.create({baseUrl: 'example.com/api'});
// applyAppTokenRefreshInterceptor(apiClient); // register the interceptor with one specific axios instance
// ...
// - With custom options:
// applyAppTokenRefreshInterceptor(apiClient, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// ); // register the interceptor with one specific axios instance
//
// PS: You may need to figure out some minor things yourself as this is just a proof of concept and not a tutorial.
// Forgive me in advance
// @see https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f

import axios, { AxiosInstance } from 'axios'
import { IResponse } from './api/types'
import TokenService from './token.service'

const shouldIntercept = (error: any) => {
  try {
    return error.response.status === 401
  } catch (e) {
    return false
  }
}

const setTokenData = (
  tokenData: {
    accessToken: string
    expiresAt: string
  },
  axiosClient: AxiosInstance
) => {
  TokenService.updateLocalAccessToken(
    tokenData.accessToken,
    tokenData.expiresAt
  )
}

const handleTokenRefresh = () => {
  const refreshToken = TokenService.getLocalRefreshToken()
  return new Promise<{
    accessToken: string
    expiresAt: string
  }>((resolve, reject) => {
    axios
      .post<
        IResponse<{
          accessToken: string
          expiresAt: string
        }>
      >(`${process.env.REACT_APP_BACKEND_URL}/api/refreshtoken`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then(({ data }) => {
        const tokenData = {
          accessToken: data.data.accessToken,
          expiresAt: data.data.expiresAt,
        }
        resolve(tokenData)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const attachTokenToRequest = (request: any, token: string) => {
  request.headers['Authorization'] = `Bearer ${token}`

  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
  //   if (/\/orders/.test(request.url)) {
  //     request.params.token = token
  //   }
}

const refreshTokenService = (
  axiosClient: AxiosInstance,
  customOptions = {}
) => {
  let isRefreshing = false
  let failedQueue: any[] = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  }
  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const interceptor = async (error: any) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token: any) => {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, token)
          return axiosClient.request(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(error) // Ignore refresh token request's "err" and return actual "error" for the original request
        })
    }

    originalRequest._retry = true
    isRefreshing = true
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient)
          options.attachTokenToRequest(originalRequest, tokenData.accessToken)
          processQueue(null, tokenData.accessToken)
          resolve(axiosClient.request(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          document.dispatchEvent(new Event('UserLogout'))
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}

export default refreshTokenService
