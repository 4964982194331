import { format } from 'date-fns'
import { FC } from 'react'
import { ActionButton } from '../../../../../atoms/ActionButton/ActionButton'
import Table from '../../../../../atoms/Table/Table'
import { IAppointment } from '../../../../../models/Appointment'
import { IPatient } from '../../../../../models/Patient'
import APIService from '../../../../../services/api'
import { StyledDoctorCategorySquare } from '../../styled'

interface IProps {
  patient: IPatient
  appointments: IAppointment[]
  isLoading?: boolean
}

export const PatientAppointments: FC<IProps> = ({
  patient,
  appointments = [],
  isLoading,
}) => {
  return (
    <Table
      className="mb-4"
      tableSize="md-2"
      isLoading={isLoading}
      isTableHovered={false}
      noResultsText={'No appointments found'}
      tableHead={[
        { title: 'Doctor' },
        { title: 'Category' },
        { title: 'Date of visit' },
        { title: 'Report' },
      ]}
      rows={appointments.map((appointment, i) => {
        return {
          className: '',
          cols: [
            {
              children: (
                <>
                  Dr. {appointment.doctor?.name} {appointment.doctor?.surname}
                </>
              ),
            },
            {
              className: 'text-bold-500',
              children: (
                <>
                  <StyledDoctorCategorySquare
                    $categoryColor={appointment.category?.color}
                  />{' '}
                  {appointment.category?.name}
                </>
              ),
            },
            {
              className: 'text-bold-500',
              children: (
                <>
                  {appointment.date_start
                    ? format(
                        new Date(appointment.date_start),
                        'dd/MM/yyyy HH:mm'
                      )
                    : null}
                </>
              ),
            },
            {
              className: 'text-right',
              children: (
                <>
                  {appointment.has_report && (
                    <ActionButton
                      buttonType="download"
                      className="btn-primary"
                      isAsync={true}
                      onClick={async (e) =>
                        APIService.downloadFile({
                          url: APIService.patient.patients.getPatientReportPdfUrl(
                            patient.uuid,
                            appointment.id
                          ),
                        })
                      }
                    />
                  )}
                </>
              ),
            },
          ],
        }
      })}
    />
  )
}
