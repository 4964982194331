import { AxiosProgressEvent } from 'axios'
import { IPatient } from '../../../models/Patient'
import api from '../../api.service'
import { IResponsePaginated } from '../types'
import { PatientType } from './types'

// Patients
const list = ({
  search,
  page,
  limit,
  sortMethod,
  sortColumn,
  archived,
}: {
  search?: string | null
  page: number
  limit: number
  sortColumn?: string
  sortMethod?: string
  archived?: boolean
}) => {
  return api.get<IResponsePaginated<IPatient[]>>(`/api/manage/patients`, {
    params: {
      q: search || null,
      page: page,
      limit: limit,
      sort_method: sortMethod || null,
      sort_column: sortColumn || null,
      archived: archived ? 1 : 0,
    },
  })
}

const getPatient = ({ uuid }: { uuid: string }) => {
  return api.get<IPatient>(`/api/manage/patient/${uuid}`)
}

const createPatient = (payload: PatientType) => {
  return api.post<IPatient>(`/api/manage/patients`, payload)
}

const editPatient = (patientId: string, payload: PatientType) => {
  return api.put<IPatient>(`/api/manage/patient/${patientId}`, payload)
}

const archivePatient = (patientId: string) => {
  return api.put(`/api/manage/patient/${patientId}/archive`)
}

const restorePatient = (patientId: string) => {
  return api.put(`/api/manage/patient/${patientId}/restore`)
}

const anonymizePatient = (patientId: string) => {
  return api.post<IPatient>(`/api/manage/patient/${patientId}/anonymize`)
}

const uploadPatientFile = (
  patientId: string,
  {
    file,
    categoryId,
    fileName,
    note,
  }: {
    file: File
    categoryId: number | null
    fileName: string | null
    note: string | null
  },
  onUploadProgress?: (e: AxiosProgressEvent) => void,
  percentCompleted?: (e: number) => void
) => {
  const formData = new FormData()
  formData.append('file_upload', file)
  if (categoryId) {
    formData.append('file_category', categoryId.toString())
  }
  if (fileName) {
    formData.append('patient_file_name', fileName)
  }
  if (note) {
    formData.append('file_note', note)
  }

  return api.post(`/api/manage/patient/${patientId}/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        onUploadProgress(progressEvent)
      }
      if (percentCompleted && progressEvent.total) {
        const completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        percentCompleted(completed)
      }
    },
  })
}

// TODO GET file
// TODO Delete file

const API = {
  list,
  getPatient,
  createPatient,
  editPatient,
  archivePatient,
  restorePatient,
  anonymizePatient,
  uploadPatientFile,
}

export default API
