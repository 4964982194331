import styled from 'styled-components'

export const StyledTableSearchInput = styled.div`
  max-width: 400px;

  .input-group-text {
    i {
      &.bi {
        width: 24px;
        height: 24px;
      }
    }
  }
`
