import { AxiosProgressEvent } from 'axios'
import api from '../../api.service'
import { DoctorCreateType, DoctorFileType, DoctorType } from './types'

// Doctors
const list = ({
  search,
  page,
  limit,
  sortMethod,
  sortColumn,
  deleted,
}: {
  search?: string | null
  page: number
  limit: number
  sortColumn?: string
  sortMethod?: string
  deleted?: boolean
}) => {
  return api.get(`/api/manage/doctors`, {
    params: {
      q: search || null,
      page: page,
      limit: limit,
      sort_method: sortMethod || null,
      sort_column: sortColumn || null,
      deleted: deleted ? 1 : 0,
    },
  })
}

const getDoctor = (doctorId: string) => {
  return api.get(`/api/manage/doctor/${doctorId}`)
}

const createDoctor = (payload: DoctorCreateType) => {
  return api.post(`/api/manage/doctors`, payload)
}

const editDoctor = (doctorId: string, payload: DoctorType) => {
  return api.put(`/api/manage/doctor/${doctorId}`, payload)
}

const uploadDoctorFile = (
  doctorId: string,
  {
    file,
    type,
  }: {
    file: File
    type:
      | 'id'
      | 'cv'
      | 'gmc'
      | 'elca'
      | 'dbs'
      | 'ref1'
      | 'ref2'
      | 'ohc'
      | 'ftw'
      | 'indm'
  },
  onUploadProgress?: (e: AxiosProgressEvent) => void,
  percentCompleted?: (e: number) => void
) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)

  return api.post(`/api/manage/doctor/${doctorId}/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        onUploadProgress(progressEvent)
      }
      if (percentCompleted && progressEvent.total) {
        const completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        percentCompleted(completed)
      }
    },
  })
}

const uploadDoctorSignatureImage = (
  doctorId: string,
  file: File,
  onUploadProgress?: (e: AxiosProgressEvent) => void,
  percentCompleted?: (e: number) => void
) => {
  const formData = new FormData()
  formData.append('file', file)

  return api.post(
    `/api/manage/doctor/${doctorId}/file/upload/signature`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) {
          onUploadProgress(progressEvent)
        }
        if (percentCompleted && progressEvent.total) {
          const completed = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          percentCompleted(completed)
        }
      },
    }
  )
}

const downloadDoctorFile = (doctorId: string, fileType: DoctorFileType) => {
  return api.get(`/api/manage/doctor/${doctorId}/file/${fileType}`)
}

const getDoctorFileUrl = (doctorId: string, fileType: DoctorFileType) => {
  return `/api/manage/doctor/${doctorId}/file/${fileType}`
}

const API = {
  list,
  getDoctor,
  createDoctor,
  editDoctor,
  uploadDoctorFile,
  uploadDoctorSignatureImage,
  downloadDoctorFile,
  getDoctorFileUrl,
}

export default API
