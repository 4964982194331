import { FC } from 'react'
import { IPatient } from '../../../../../models/Patient'

interface IProps {
  patient: IPatient
}

export const PatientDetailsTabs: FC<IProps> = ({ patient }) => {
  return (
    <div className="">
      <ul className="nav nav-tabs mb-4" id="patientDetailsTab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="patient-details-tab"
            data-bs-toggle="tab"
            href="#patient-details"
            role="tab"
            aria-controls="patient-details"
            aria-selected="true"
          >
            Patient's details
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="next-of-kin-tab"
            data-bs-toggle="tab"
            href="#next-of-kin"
            role="tab"
            aria-controls="next-of-kin"
            aria-selected="false"
          >
            Next of kin
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="gp-details-tab"
            data-bs-toggle="tab"
            href="#gp-details"
            role="tab"
            aria-controls="gp-details"
            aria-selected="false"
          >
            GP details
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="other-tab"
            data-bs-toggle="tab"
            href="#other"
            role="tab"
            aria-controls="other"
            aria-selected="false"
          >
            Other
          </a>
        </li>
      </ul>
      <div className="tab-content" id="patientDetailsTabContent">
        <div
          className="tab-pane fade active show"
          id="patient-details"
          role="tabpanel"
          aria-labelledby="patient-details-tab"
        >
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Name</label>
                <p className="form-control-static">{patient.name || '--'}</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Surname</label>
                <p className="form-control-static">{patient.surname || '--'}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Date of birth</label>
                <p className="form-control-static">
                  {patient.date_of_birth || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Nationality</label>
                <p className="form-control-static">
                  {patient.nationality?.nicename || '--'}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Mobile</label>
                <p className="form-control-static">
                  <a href={`tel:${patient.mobile}`}>{patient.mobile || '--'}</a>
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Email</label>
                <p className="form-control-static">
                  <a href={`mailto:${patient.email}`}>
                    {patient.email || '--'}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="divider divider-center">
            <div className="divider-text">Residential address</div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Address 1</label>
                <p className="form-control-static">
                  {patient.address1 || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Address 2</label>
                <p className="form-control-static">
                  {patient.address2 || '--'}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Postcode</label>
                <p className="form-control-static">
                  {patient.postcode || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>City</label>
                <p className="form-control-static">{patient.city || '--'}</p>
              </div>
            </div>
          </div>

          <div className="divider divider-center">
            <div className="divider-text">Billing details</div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Billing name</label>
                <p className="form-control-static">
                  {patient.billing_name || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Billing surname</label>
                <p className="form-control-static">
                  {patient.billing_surname || '--'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="next-of-kin"
          role="tabpanel"
          aria-labelledby="next-of-kin-tab"
        >
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Name</label>
                <p className="form-control-static">
                  {patient.kin_name || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Phone</label>
                <p className="form-control-static">
                  {patient.kin_phone || '--'}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Address 1</label>
                <p className="form-control-static">
                  {patient.kin_address1 || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Address 2</label>
                <p className="form-control-static">
                  {patient.kin_address2 || '--'}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>City</label>
                <p className="form-control-static">
                  {patient.kin_city || '--'}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Postcode</label>
                <p className="form-control-static">
                  {patient.kin_postcode || '--'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="gp-details"
          role="tabpanel"
          aria-labelledby="gp-details-tab"
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Name</label>
                <p className="form-control-static">{patient.gp_name || '--'}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Phone</label>
                <p className="form-control-static">
                  <a href={`tel:${patient.gp_phone}`}>
                    {patient.gp_phone || '--'}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Email</label>
                <p className="form-control-static">
                  <a href={`mailto:${patient.gp_email}`}>
                    {patient.gp_email || '--'}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>Address</label>
                <p className="form-control-static">
                  {patient.gp_address || '--'}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>City</label>
                <p className="form-control-static">{patient.gp_city || '--'}</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Postcode</label>
                <p className="form-control-static">
                  {patient.gp_postcode || '--'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="other"
          role="tabpanel"
          aria-labelledby="other"
        >
          <div className="divider divider-center">
            <div className="divider-text">Insurance details</div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Are you insured?</label>
                <p className="form-control-static">
                  {patient.insured ? `Yes` : `No`}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Are you happy for us to write to your gp/referring consultant?
                </label>
                <p className="form-control-static">
                  {patient.insurance_gp ? `Yes` : `No`}
                </p>
              </div>
            </div>
          </div>

          <div className="divider divider-center">
            <div className="divider-text">Other details</div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Are you happy to be contacted by us for marketing purposes?
                </label>
                <p className="form-control-static">
                  {patient.marketing_agree === 0 && `Not specified`}
                  {patient.marketing_agree === 1 && `No`}
                  {patient.marketing_agree === 2 && `Yes`}
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>ID Check</label>
                <p className="form-control-static">
                  {patient.id_check ? `Yes` : `No`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
