import { format } from 'date-fns'
import { FC } from 'react'
import { ActionButton } from '../../../../../atoms/ActionButton/ActionButton'
import Table from '../../../../../atoms/Table/Table'
import { IPatient } from '../../../../../models/Patient'
import APIService from '../../../../../services/api'
import { StyledDoctorCategorySquare } from '../../styled'
import { IGPLetter } from '../../../../../models/GPLetter'

interface IProps {
  patient: IPatient
  gpLetters: IGPLetter[]
  isLoading?: boolean
}

export const PatientGpLetters: FC<IProps> = ({
  patient,
  gpLetters = [],
  isLoading,
}) => {
  return (
    <Table
      className="mb-4"
      tableSize="md-2"
      isLoading={isLoading}
      isTableHovered={false}
      noResultsText={'No appointments found'}
      tableHead={[
        { title: 'Doctor' },
        { title: 'Category' },
        { title: 'Date' },
        { title: '' },
      ]}
      rows={gpLetters.map((gpLetter, i) => {
        return {
          className: '',
          cols: [
            {
              children: <>Dr. {gpLetter.doctor?.full_name}</>,
            },
            {
              className: 'text-bold-500',
              children: (
                <>
                  <StyledDoctorCategorySquare
                    $categoryColor={gpLetter.appointment?.category?.color}
                  />{' '}
                  {gpLetter.appointment?.category?.name}
                </>
              ),
            },
            {
              className: 'text-bold-500',
              children: (
                <>
                  {gpLetter.appointment?.date_start
                    ? format(
                        new Date(gpLetter.appointment?.date_start),
                        'dd/MM/yyyy'
                      )
                    : gpLetter.created_at
                      ? format(new Date(gpLetter.created_at), 'dd/MM/yyyy')
                      : ''}
                </>
              ),
            },
            {
              className: 'text-right',
              children: (
                <>
                  <ActionButton
                    buttonType="download"
                    className="btn-primary"
                    isAsync={true}
                    onClick={async (e) =>
                      APIService.downloadFile({
                        url: APIService.patient.patients.getPatientGpLetterPdfUrl(
                          patient.uuid,
                          gpLetter.id
                        ),
                      })
                    }
                  />
                </>
              ),
            },
          ],
        }
      })}
    />
  )
}
