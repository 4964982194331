import { FC } from 'react'

interface IProps {
  content: string
  className?: string
  style?: 'bg-primary' | 'bg-default' | 'bg-warning'
  size?: 'sm' | 'md' | 'md-2' | 'lg' | 'xl'
  marginRight?: boolean
}

export const Avatar: FC<IProps> = ({
  content,
  className,
  style = 'bg-primary',
  size,
  marginRight,
}) => {
  return (
    <div
      className={`avatar ${size ? `avatar-${size}` : ''} ${style} ${
        marginRight ? 'me-3' : ''
      } ${className}`}
    >
      <span className="avatar-content">{content}</span>
    </div>
  )
}
