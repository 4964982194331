import { format } from 'date-fns'
import { FC } from 'react'
import { IAppointment } from '../../models/Appointment'

import {
  StyledAppointmentChip,
  StyledAppointmentDateWrapper,
  StyledCalendarIcon,
  StyledDoctorWrapper,
  StyledPatientWrapper,
} from './styled'

interface IPropsPatientAppointmentChip {
  appointment: IAppointment
  showPatient?: boolean
  onClick?: () => void
  onClickPatient?: () => void
  onClickDoctor?: () => void
  onClicAppointment?: () => void
}

const getFormattedAppointmentDate = (
  value: string | null,
  dateFormat = 'dd/MM/yyyy HH:mm'
) => {
  if (!value) return null
  return format(new Date(value), dateFormat)
}

const CalendarIcon = () => (
  <StyledCalendarIcon className="bi" fill="currentColor">
    <use xlinkHref="/assets/images/bootstrap-icons.svg#calendar2-check"></use>
  </StyledCalendarIcon>
)

export const PatientAppointmentChip: FC<IPropsPatientAppointmentChip> = ({
  appointment,
  showPatient = true,
  onClick,
  onClickPatient,
  onClickDoctor,
  onClicAppointment,
}) => {
  return (
    <StyledAppointmentChip
      $categoryColor={appointment.category?.color}
      onClick={onClick}
    >
      {showPatient && (
        <StyledPatientWrapper onClick={onClickPatient}>
          {appointment.patient?.name} {appointment.patient?.surname}
        </StyledPatientWrapper>
      )}

      <StyledDoctorWrapper
        $categoryName={appointment.category?.name}
        onClick={onClickDoctor}
      >
        Dr. {appointment.doctor?.name} {appointment.doctor?.surname}
      </StyledDoctorWrapper>
      <StyledAppointmentDateWrapper onClick={onClicAppointment}>
        <CalendarIcon /> {getFormattedAppointmentDate(appointment.date_start)} -{' '}
        {getFormattedAppointmentDate(appointment.date_end, 'HH:mm')}
      </StyledAppointmentDateWrapper>
    </StyledAppointmentChip>
  )
}
