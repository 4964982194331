import { createContext, ReactNode, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router'
import TokenService from '../services/token.service'
import APIService from '../services/api'
import {
  AuthContextDispatchType,
  AuthContextType,
  AuthStateContextType,
} from './types'

const INITIAL_STATE: AuthStateContextType = {
  user: null,
}

export const loginReducer = (
  state: AuthStateContextType,
  action: AuthContextDispatchType
): AuthStateContextType => {
  switch (action.type) {
    case 'change_user':
      return { ...state, user: action.payload }
    default:
      return state
  }
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthContextProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const navigate = useNavigate()
  const userRaw = localStorage.getItem('userDL')
  const user = userRaw ? JSON.parse(userRaw) : null

  const [loginState, loginStateDispatch] = useReducer(loginReducer, {
    ...INITIAL_STATE,
    user: user,
  })

  const handleLogout = async (_: unknown, redirectToLogin: boolean = true) => {
    try {
      await APIService.logout()
    } catch (error) {}

    TokenService.removeUser()
    TokenService.removeAuthToken()

    loginStateDispatch({ type: 'change_user', payload: null })

    if (redirectToLogin) {
      navigate('/login', { replace: true })
    }
  }

  const userLoggedInEvent = () => {}

  useEffect(() => {
    document.addEventListener('UserLogout', handleLogout, false)
    document.addEventListener('UserLoggedIn', userLoggedInEvent, false)

    return () => {
      document.removeEventListener('UserLogout', handleLogout, false)
      document.removeEventListener('UserLoggedIn', userLoggedInEvent, false)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        state: loginState,
        dispatch: loginStateDispatch,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
