import { ReactNode } from 'react'

const Card = ({
  className,
  title,
  children,
  footer,
}: {
  className?: string
  title?: string
  footer?: ReactNode
  children: ReactNode
}) => {
  return (
    <div className={`card ${className}`}>
      {title && (
        <div className="card-header">
          <h4 className="card-title">{title}</h4>
        </div>
      )}

      <div className="card-body">{children}</div>

      {footer && <div className="card-footer">{footer}</div>}
    </div>
  )
}

export default Card
