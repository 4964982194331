import AppointmentsApi from './AppointmentsApi'
import DoctorsApi from './DoctorsApi'
import PatientsApi from './PatientsApi'
import ServicesApi from './ServicesApi'

const AdminAPI = {
  appointments: AppointmentsApi,
  doctors: DoctorsApi,
  patients: PatientsApi,
  services: ServicesApi,
}

export default AdminAPI
