import { ReactNode } from 'react'

const PageHeading = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => {
  return <div className={`page-heading ${className}`}>{children}</div>
}

export default PageHeading
