import './App.scss'
import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthContextProvider } from './contexts/AuthContext'
import { RequireAuth } from './auth/RequireAuth'
import { RequireGuest } from './auth/RequireGuest'
import { RequireDLUser } from './auth/RequireDLUser'
import { LoaderFull } from './atoms/Loader/Loader'
import AdminRoutes from './routes/AdminRoutes'
import PatientRoutes from './routes/PatientRoutes'
import PasswordResetPage from './pages/login/PasswordResetPage'
import PasswordChangePage from './pages/login/PasswordChangePage'
import { ToastContainer } from 'react-toastify'
import { UserType } from './models/User'

const LoginPage = React.lazy(() => import('./pages/login/LoginPage'))
const LogoutPage = React.lazy(() => import('./pages/login/LogoutPage'))
const MainSection = React.lazy(() => import('./pages/main/MainSection'))
const AdminSection = React.lazy(() => import('./pages/main/admin/AdminSection'))
const PatientSection = React.lazy(
  () => import('./pages/main/patient/PatientSection')
)
const Page404 = React.lazy(() => import('./pages/404/404'))
const Page403 = React.lazy(() => import('./pages/403/403'))
const Page500 = React.lazy(() => import('./pages/500/500'))

function App() {
  return (
    <AuthContextProvider>
      <Suspense fallback={<LoaderFull />}>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <MainSection />
              </RequireAuth>
            }
          />
          <Route
            path="/admin"
            element={
              <RequireDLUser users={[UserType.SuperAdmin, UserType.Admin]}>
                <AdminSection />
              </RequireDLUser>
            }
          >
            {AdminRoutes.map((route, idx) => (
              <Route
                key={idx}
                index={route.index ? true : false}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
          <Route
            path="/manage"
            element={
              <RequireDLUser users={[UserType.Secretary]}>
                <p>Secretary</p>
              </RequireDLUser>
            }
          />
          <Route
            path="/doctor"
            element={
              <RequireDLUser users={[UserType.Doctor]}>
                <p>Doctor</p>
              </RequireDLUser>
            }
          />
          <Route
            path="/dlnd"
            element={
              <RequireDLUser users={[UserType.Patient]}>
                <PatientSection />
              </RequireDLUser>
            }
          >
            {PatientRoutes.map((route, idx) => (
              <Route
                key={idx}
                index={route.index ? true : false}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>
          <Route
            path="login"
            element={
              <RequireGuest>
                <LoginPage />
              </RequireGuest>
            }
          />
          <Route
            path="logout"
            element={
              <RequireAuth>
                <LogoutPage />
              </RequireAuth>
            }
          />
          <Route
            path="/password/reset"
            element={
              <RequireGuest>
                <PasswordResetPage />
              </RequireGuest>
            }
          />
          <Route
            path="/password/change"
            element={
              <RequireGuest>
                <PasswordChangePage />
              </RequireGuest>
            }
          />
          <Route path="/errors/page-not-found" element={<Page404 />} />
          <Route path="/errors/unauthorized" element={<Page403 />} />
          <Route path="/errors/system-error" element={<Page500 />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </AuthContextProvider>
  )
}

export default App
