import api from '../../api.service'

export const ping = () => {
  return api.get(`/api/pingauth`)
}

// Doctors
const list = () => {
  return api.get(`/api/manage/services/list`)
}

const API = {
  list,
}

export default API
