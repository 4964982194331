import { format } from 'date-fns'
import { FC } from 'react'
import { ActionButton } from '../../../../../atoms/ActionButton/ActionButton'
import Table from '../../../../../atoms/Table/Table'
import { IPatient, IPatientFile } from '../../../../../models/Patient'

interface IProps {
  patient: IPatient
  documents: IPatientFile[]
  isLoading?: boolean
  onDownloadClick?: (document: IPatientFile) => void
}

export const PatientDocuments: FC<IProps> = ({
  patient,
  documents = [],
  isLoading,
  onDownloadClick,
}) => {
  return (
    <Table
      className="mb-4"
      tableSize="md-2"
      isLoading={isLoading}
      isTableHovered={false}
      noResultsText={'No documents found'}
      tableHead={[{ title: 'Title' }, { title: 'Upload date' }, { title: '' }]}
      rows={documents.map((document, i) => {
        return {
          className: '',
          cols: [
            {
              children: <>{document.name}</>,
            },
            {
              children: (
                <>
                  {document.created_at
                    ? format(new Date(document.created_at), 'dd/MM/yyyy HH:mm')
                    : null}
                </>
              ),
            },
            {
              className: 'text-right',
              children: (
                <>
                  <ActionButton
                    buttonType="download"
                    className="btn-primary"
                    isAsync={true}
                    onClick={async (e) => {
                      if (onDownloadClick) {
                        return onDownloadClick(document)
                      }
                    }}
                  />
                </>
              ),
            },
          ],
        }
      })}
    />
  )
}
