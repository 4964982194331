import { Loader } from '../Loader/Loader'
import { IProps } from './types'
import styles from './styles.module.scss'
import { Fragment } from 'react'

const Table = ({
  className,
  tableSize,
  isTableHovered,
  tableHead,
  rows,
  isLoading = false,
  noResultsText = 'No results found',
}: IProps) => {
  return (
    <div className="table-responsive">
      <table
        className={`table ${isTableHovered ? 'table-hover' : ''} ${
          tableSize ? `table-${tableSize}` : ''
        } ${className} ${styles.table}`}
      >
        {tableHead && (
          <thead>
            <tr>
              {tableHead.map((th, i) => (
                <th key={i} onClick={th.onClick}>
                  {th.title}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={tableHead?.length} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {!isLoading && (
            <>
              {!rows || rows.length < 1 ? (
                <tr>
                  <td colSpan={tableHead?.length} className="text-center">
                    {noResultsText}
                  </td>
                </tr>
              ) : (
                <>
                  {rows !== undefined &&
                    rows.map((row, i) => (
                      <Fragment key={i}>
                        <tr className={row.className} onClick={row.onClick}>
                          {row.cols !== undefined &&
                            row.cols.map((col, ic) => (
                              <td
                                key={ic}
                                className={col.className}
                                onClick={col.onClick}
                              >
                                {col.children}
                              </td>
                            ))}
                        </tr>
                        {row.nested && (
                          <tr>
                            <td
                              className="pt-0 pb-0"
                              colSpan={row.cols?.length}
                            >
                              {row.nested}
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Table
