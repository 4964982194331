import { FC } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
  title: string
  subtitle?: string
  breadcrumbs?: {
    title: string
    url?: string
  }[]
}

const PageTitleContainer: FC<IProps> = ({ title, subtitle, breadcrumbs }) => {
  return (
    <div className="page-title">
      <div className="row">
        <div className="col-12 col-md-6 order-md-1 order-last">
          <h3>{title}</h3>
          {subtitle && <p className="text-subtitle text-muted">{subtitle}</p>}
        </div>
        <div className="col-12 col-md-6 order-md-2 order-first">
          {breadcrumbs && breadcrumbs.length > 0 && (
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                {breadcrumbs.map((link, idx) => (
                  <li
                    key={idx}
                    className={`breadcrumb-item ${
                      idx === breadcrumbs.length - 1 ? `active` : ''
                    }`}
                    aria-current={
                      idx === breadcrumbs.length - 1 ? `page` : false
                    }
                  >
                    {link.url ? (
                      <Link to={link.url}>{link.title}</Link>
                    ) : (
                      <>{link.title}</>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          )}
        </div>
      </div>
    </div>
  )
}

export default PageTitleContainer
