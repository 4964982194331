import { FC } from 'react'
import { StyledTableSearchInput } from './TableSearchInput.style'

interface IProps {
  value: string | undefined
  placeHolder?: string
  buttonLabel?: string
  buttonType?: 'primary' | 'secondary'
  showIcon?: boolean
  onValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onButtonClick?: () => void
}

export const TableSearchInput: FC<IProps> = ({
  value,
  placeHolder,
  buttonLabel,
  buttonType = 'primary',
  onValueChange,
  onButtonClick,
}) => {
  return (
    <StyledTableSearchInput className="input-group mb-3">
      <span className="input-group-text">
        <i className="bi bi-search"></i>
      </span>
      <input
        type="text"
        className="form-control"
        placeholder={placeHolder}
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        value={value}
        onChange={onValueChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            if (onButtonClick) {
              onButtonClick()
            }
          }
        }}
      />
      {buttonLabel && (
        <button
          className={`btn btn-${buttonType}`}
          type="button"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </button>
      )}
    </StyledTableSearchInput>
  )
}
