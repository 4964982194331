import { FC } from 'react'
import { StyledLoader, StyledLoaderTable } from './Loader.style'

interface IProps {}

export const Loader: FC<IProps> = () => {
  return (
    <StyledLoaderTable className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </StyledLoaderTable>
  )
}

export const LoaderFull: FC<IProps> = () => {
  return (
    <StyledLoader>
      <Loader />
    </StyledLoader>
  )
}
