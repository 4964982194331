import { FC } from 'react'
import { StyledPagination } from './Pagination.style'
import { IProps } from './types'

export const Pagination: FC<IProps> = ({
  className = 'pagination-primary',
  style = 'sm',
  currentPage,
  totalPages,
  onChangePage,
}) => {
  return (
    <StyledPagination aria-label="Page navigation">
      <ul className={`pagination pagination-${style} ${className}`}>
        <li className="page-item">
          <div
            className="page-link"
            onClick={() => {
              if (currentPage > 1) {
                if (onChangePage) {
                  onChangePage(currentPage - 1)
                }
              }
            }}
          >
            <span aria-hidden="true">
              <i className="bi bi-chevron-left"></i>
            </span>
          </div>
        </li>
        {Array.from({ length: totalPages }).map((it, i) => (
          <li
            key={i}
            className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
          >
            <div
              className="page-link clickable"
              onClick={() => {
                if (onChangePage) {
                  onChangePage(i + 1)
                }
              }}
            >
              {i + 1}
            </div>
          </li>
        ))}
        <li className="page-item">
          <div
            className="page-link"
            onClick={() => {
              if (currentPage < totalPages) {
                if (onChangePage) {
                  onChangePage(currentPage + 1)
                }
              }
            }}
          >
            <span aria-hidden="true">
              <i className="bi bi-chevron-right"></i>
            </span>
          </div>
        </li>
      </ul>
    </StyledPagination>
  )
}
