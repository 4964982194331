import { ReactNode } from 'react'

const PageContent = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => {
  return <div className={`page-content ${className}`}>{children}</div>
}

export default PageContent
