import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import { Avatar } from '../../../atoms/Avatar/Avatar'
import { Button } from '../../../atoms/Button/Button'
import Card from '../../../atoms/Card/Card'
import { LoaderFull } from '../../../atoms/Loader/Loader'
import {
  PageContent,
  PageHeading,
  PageTitleContainer,
} from '../../../components/Layout'
import { IUser } from '../../../models/User'
import APIService from '../../../services/api'
import { StyledLoaderWrapper, StyledUserCardWrapper } from './styled'

const ProfilePage = () => {
  const [userProfile, setUserProfile] = useState<IUser>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string | null>(null)

  const getUserProfile = async () => {
    setIsLoading(true)
    try {
      const { data } = await APIService.me()
      setUserProfile(data.data)
    } catch (error) {}

    setIsLoading(false)
  }

  const handleChangePassword = async () => {
    setPasswordError(null)
    setIsSubmitting(true)

    try {
      const { data, status } = await APIService.patient.editProfile(
        password,
        passwordConfirm
      )
      toast.success(data.message)
    } catch (error: any) {
      setIsSubmitting(false)

      const errorPassword = error?.response?.data?.errors?.password?.[0]
      const errorPasswordConfirm =
        error?.response?.data?.errors?.password_confirmation?.[0]
      if (errorPassword) {
        setPasswordError(errorPassword)
      } else if (errorPasswordConfirm) {
        setPasswordError(errorPasswordConfirm)
      } else {
        setPasswordError(
          'There was an error. Please try again later or contact our support.'
        )
      }
    }
    setPassword('')
    setPasswordConfirm('')
    setIsSubmitting(false)
  }

  useEffect(() => {
    getUserProfile()
  }, [])

  return (
    <>
      <Helmet title={`My profile - ${process.env.REACT_APP_NAME}`} />
      <PageHeading>
        <PageTitleContainer
          title={``}
          // subtitle="Subtitle"
          breadcrumbs={[
            { title: 'Home', url: '/dlnd' },
            { title: 'My profile', url: undefined },
          ]}
        />
      </PageHeading>
      <PageContent className="mb-4">
        <section className="row">
          <div className="col-12 col-lg-8 order-2 order-lg-1">
            <div className="row">
              <div className="col-12">
                <Card title={`Profile`}>
                  {isLoading && (
                    <StyledLoaderWrapper>
                      <LoaderFull />
                    </StyledLoaderWrapper>
                  )}

                  {!isLoading && userProfile && (
                    <>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Name</label>
                            <p className="form-control-static">
                              {userProfile.name || '--'}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Surname</label>
                            <p className="form-control-static">
                              {userProfile.surname || '--'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Email</label>
                            <p className="form-control-static">
                              {userProfile.email || '--'}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Phone</label>
                            <p className="form-control-static">
                              {userProfile.phone || '--'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              className={`form-control ${
                                passwordError !== null ? 'is-invalid' : ''
                              }`}
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {passwordError !== null && (
                              <div className="invalid-feedback">
                                <i className="bx bx-radio-circle"></i>
                                {passwordError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Confirm password</label>
                            <input
                              className="form-control"
                              type="password"
                              value={passwordConfirm}
                              onChange={(e) =>
                                setPasswordConfirm(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="text-muted">
                            * If you want to change other details of your
                            profile, please send an email to{' '}
                            <a href="mailto:info@dottorelondon.com">
                              info@dottorelondon.com
                            </a>
                          </p>
                        </div>
                        <div className="col-12 d-flex justify-content-end mt-4">
                          <Button
                            type="button"
                            className="btn btn-primary me-1 mb-1"
                            isAsync={true}
                            onClick={handleChangePassword}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 order-1 order-lg-2">
            <div className="row">
              <div className="col-12">
                <Card>
                  {isLoading && (
                    <StyledLoaderWrapper>
                      <LoaderFull />
                    </StyledLoaderWrapper>
                  )}

                  {!isLoading && userProfile && (
                    <StyledUserCardWrapper>
                      <Avatar
                        className="mb-3"
                        size="xl"
                        content={`${userProfile.name.charAt(
                          0
                        )}${userProfile.surname.charAt(0)}`}
                      />
                      <h4>
                        {userProfile.name} {userProfile.surname}
                      </h4>
                      {userProfile.email && (
                        <div className="mb-1">
                          <a href={`mailto:${userProfile.email}`}>
                            {userProfile.email}
                          </a>
                        </div>
                      )}
                      {userProfile.phone && (
                        <div className="mb-1">
                          <a href={`tel:${userProfile.phone}`}>
                            {userProfile.phone}
                          </a>
                        </div>
                      )}
                    </StyledUserCardWrapper>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </section>
      </PageContent>
    </>
  )
}

export default ProfilePage
