import { useState } from 'react'
import './LoginPage.scss'
import APIService from '../../services/api'

const PasswordResetPage = () => {
  const [email, setEmail] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setEmailError(null)
    setIsSubmitting(true)

    try {
      await APIService.resetPassword(email)
      setEmail('')
      setShowSuccess(true)
    } catch (error) {
      setEmailError('Please provide a valid email address.')
    }
    setIsSubmitting(false)
  }

  const canSubmitForm = (): boolean => {
    if (email && email.length > 5) {
      return true
    }
    return false
  }

  return (
    <div id="auth">
      <div className="row h-100">
        <div className="col-12">
          <div id="auth-left">
            <div className="auth-logo">
              <img
                src="/assets/logo/logo-dottore-london-trasp-768x444.png"
                alt="Logo"
              />
            </div>

            <form onSubmit={handleResetPassword}>
              <div className="form-group position-relative has-icon-left mb-4">
                <input
                  type="text"
                  className={`form-control form-control-xl ${
                    emailError !== null ? 'is-invalid' : ''
                  }`}
                  placeholder="youremail@email.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailError(null)
                  }}
                  autoComplete="username"
                />
                <div className="form-control-icon">
                  <i className="bi bi-person"></i>
                </div>
                {emailError !== null && (
                  <div className="invalid-feedback">
                    <i className="bx bx-radio-circle"></i>
                    {emailError}
                  </div>
                )}
              </div>
              <button
                className="btn btn-primary btn-block btn-lg shadow-lg mt-3"
                disabled={isSubmitting || !canSubmitForm()}
              >
                {isSubmitting === false ? (
                  <>Reset password</>
                ) : (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </form>

            {showSuccess && (
              <div className="alert alert-light-info color-info mt-4">
                <i className="bi bi bi-exclamation-circle"></i> If your email
                exists in our system, a link will arrive in your inbox shortly.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage
