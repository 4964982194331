import { RouteType } from '../models/RouteType'
import DashboardPage from '../views/patient/dashboard/DashboardPage'
import PatientPage from '../views/patient/patient/Patient'
import ProfilePage from '../views/patient/profile/ProfilePage'

const routes: RouteType[] = [
  { index: true, element: <DashboardPage /> },
  { path: 'profile', element: <ProfilePage /> },
  { path: 'patient/:patientId', element: <PatientPage /> },
]

export default routes
