import styled, { css } from 'styled-components'

export const StyledAppointmentChip = styled.div<{
  $categoryColor?: string | null
  onClick?: () => void
}>`
  position: relative;
  font-size: 14px;
  margin-bottom: 14px;

  ${({ $categoryColor }) =>
    $categoryColor &&
    css`
      padding-left: 15px;
      padding-right: 15px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 3px;
        height: 100%;
        background-color: ${$categoryColor};
      }
    `};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`

export const StyledPatientWrapper = styled.div<{
  onClick?: () => void
}>`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 1px solid;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`

export const StyledDoctorWrapper = styled.div<{
  $categoryName?: string | null
  onClick?: () => void
}>`
  /* font-size: 12px; */
  font-weight: bold;
  margin-bottom: 3px;

  ${({ $categoryName }) =>
    $categoryName &&
    css`
      &::after {
        content: '${$categoryName}';
        display: block;
        font-weight: normal;
        font-size: 12px;
      }
    `};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`

export const StyledAppointmentDateWrapper = styled.div<{
  onClick?: () => void
}>`
  font-size: 12px;
  font-weight: normale;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`

export const StyledCalendarIcon = styled.svg<any>`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  top: -1px;
  display: inline-block;
  position: relative;
`
